import React from "react";
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layouts/layout.js";
import Seo from "../components/seo.js";
import SectionBottomCta from "../components/SectionBottomCta.js";

import { Section1ColWhite, Wrapper, SectionH2, SectionParag } from "../styles/style.js";

const AboutPage = () => (
  <Layout>
    <Seo
      title="About Us"
      description="We are your All Event Limousine Company, serving all of Southern California for the past 23 years. Whether it is just for you and a date or your 30 closest friends, we can help you with any transportation needs you may have."
    />

    <div className="relative z-0" style={{ display: "grid", background: "#000000" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.9",
          height: 500,
        }}
        alt="southern california limo"
        src="../img/header-about-bg.jpg"
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 max-w-screen-md mx-auto">
          <div className="text-white text-5xl md:text-7xl font-extrabold text-center">About Us</div>
        </div>
      </div>
    </div>

    <Section1ColWhite>
      <Wrapper>
        <SectionH2>Our Amazing Story</SectionH2>
        <SectionParag>
          Welcome to Amazing Limousines. We are your “All Event Limousine Company”, serving all of Southern California for the past 23 years. Whether it is just for you and a date or your 30 closest friends, we can help you with any transportation needs you may have: Airport Drop Off and Pick Ups, Anniversaries, Award Ceremonies, Bachelor/Bachelorette Parties, Birthdays, Casino Nights, Charters, Concerts, Conventions, Corporate Functions, Dinner with Friends, Engagements, Funerals, Graduations, Homecomings, Night Out, Proms, Proposals, Quinceaneras, Sightseeing, Special Events, Sporting Events, Theaters, Vegas Trips, Weddings, Wine Tours and so many more.
        </SectionParag>
        <SectionParag>
          From your front door, to the entrance of any destination you like, your chauffeur will be at your pick up location on time, dressed professional, ready to help make your experience amazing and make sure that you feel like You Are The Star! We offer the most competitive rates in the market and boast the highest level of customer service.
        </SectionParag>
        <SectionParag>
          Amazing Limousines is a family owned business with locations in Riverside and Orange County, CA and has been serving Southern California for over two decades. Amazing Limousines founding principles consist of Family, Integrity and Accountability.
        </SectionParag>
        <SectionParag>
          When choosing Amazing Limousines for your next special event, rest assured YOU are the star and we will make sure that your experience is spectacular.
        </SectionParag>
      </Wrapper>
    </Section1ColWhite>

    <SectionBottomCta grayBackground />
  </Layout>
);

export default AboutPage